import React from 'react'
import '../../styles/generalsmallloader.css'

export default function GeneralSmallLoader() {
    return (
        <div className="general-small-loader flex inset-0 justify-center items-center">
            <div className='small-loading-roller'>
            </div>
        </div>
    )
}
